<template>
    <div class="application-info">Zadejte pohlaví, jméno, příjmení a datum narození dítěte a vyberte mateřskou školu pro podání žádosti o přijetí. 
    </div>
   <BaseForm  
        :form_class="'form_small'" 
        :acceptButton="''"
        :cancelButton="''"
        v-bind="$attrs"
    >
        <template v-slot:rows_after>
            <div class="school-buttons">
                <div class="school-button" v-for="(school,index) in schools" :key="index" >
                    <BaseButton type="button" class="btn-primary" v-if="school.status > 0" @click.prevent="nextPage(school)">
                        {{ school.name }}, {{ school.address }}
                    </BaseButton>
                </div>
            </div>   
            <div class="reg-number-block">         
                <BaseButton>
                    Kontrola registrační čísla žádosti pro zadané dítě
                </BaseButton>
                <div class="reg-number">
                    {{ regNumber }}
                </div>
            </div>        
        </template>
    </BaseForm>
    
    <div class="phone-info">(dotazy a informace ve všední den od 8:00 do 18:00 na telefonním čísle 604 684 395)</div>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm'; 
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import useFormData from '@common/composable/BaseFormData';


export default {
    name: "ApplicationFormPage1",
    components: {      
        BaseForm,
    },
    emits: ['nextPage'],
    setup(props,context) {
        const { data,validate_form,setRows,store_action,formInit,formActions } = useFormData();
        formInit();
        
        formActions.value.afterSubmit = () => {};
        
        store_action.value = 'application/getRegNumber';
        
        if(!Object.prototype.hasOwnProperty.call(data.value,'statni_prislusnost')){
            data.value['statni_prislusnost'] = "Česká republika";
        }
        
        const store = useStore();

        const i18n = useI18n();
            
        setRows([
            {
                labels: ['Přihlašované dítě'],
                items: [
                    {
                        title: "Pohlaví",
                        type: 'select',
                        key: "pohlavi",
                        class: "w-l",
                        required: true,
                        options: () => [
                            {
                                title: 'chlapec',
                                code: 10,
                            },
                            {
                                title: 'dívka',
                                code: 20,
                            }
                        ]
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.child_name')],
                items: [
                    {
                        title: i18n.t('enrollment.child_name'),
                        type: 'text',
                        key: "jmeno_ditete",
                        class: "w-xl",
                        required: true,
                        rules: {
                            lengthMin: 2,
                            lenghtMax: 64,
                        }
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.child_surname')],
                items: [
                    {
                        title: i18n.t('enrollment.child_surname'),
                        type: 'text',
                        key: "prijmeni_ditete",
                        class: "w-xl",
                        required: true,
                        rules: {
                            lengthMin: 2,
                            lenghtMax: 64,
                        }
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.child_birthday')],
                items: [
                    {
                        title: i18n.t('enrollment.child_birthday'),
                        label: i18n.t('enrollment.date_format'),
                        type: 'text',
                        key: "datumNarozeni",
                        class: "w-xl",
                        required: true,
                        rules: {
                            isDate: true,
                        }
                    }
                ]
            },
        ]);

        const schools = computed(() => store.getters['school/getItems']);

        const canApply = computed(() => store.getters['application/canApply']);
        store.commit('application/setCanApply',false)
        const nextPage = (school) => {
            if(validate_form()){
                data.value['school'] = school;
                data.value['schoolAppliedCode'] = school.item_key;
                store.dispatch('application/checkCanApply',{data: data.value});
            }
        };
        watch(() => canApply.value,() => {
            if(canApply.value){
                context.emit('nextPage');
            }
        })

        store.commit('application/setRegNumber','');
        const regNumber = computed(() => store.getters['application/getRegNumber']);

        return {
            schools,
            nextPage,
            regNumber
        }
    }
}
</script>

<style scoped>
.base-form{
    margin: 1rem;
}
.school-buttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
}

.school-button>button{
    width: 12rem;
    height: 5rem;
    margin: 0.5rem;
}
.reg-number-block{
    display: flex;
    align-items: center;
    border-top: 1px solid lightgray;
    padding-top: 1rem;
}
.reg-number{
    margin-left: 1rem;
}
.application-info{
    font-size: 0.8rem;
}
.phone-info{
    font-size: 0.7rem;
}
</style>
